import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

const Web3AccountContext = createContext(null);

function useWeb3Account() {
  const context = useContext(Web3AccountContext);

  if (!context) {
    throw new Error(
      `useWeb3Account must be used within a Web3Account Provider`
    );
  }

  return context;
}

function Web3AccountProvider(props) {
  const [web3Account, setWeb3Account] = useState();
  const value = useMemo(() => [web3Account, setWeb3Account], [web3Account]);
  return <Web3AccountContext.Provider value={value} {...props} />;
}

///

function reducer(state, action) {
  switch (action.type) {
    case "SET_WEB3_PROVIDER":
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        address: action.address,
      };
    case "SET_CHAIN_ID":
      return {
        ...state,
        chainId: action.chainId,
      };
    case "RESET_WEB3_PROVIDER":
      return initialState;
    default:
      throw new Error();
  }
}

const initialState = {
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
};

const wcContext = createContext({
  state: {},
  dispatch: (action) => {},
  actions: {},
});

const WalletConnectContext = React.memo(({ children }) => {
  // const [enhancedActions, setEnhancedActions] = React.useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const { provider, web3Provider, address, chainId } = state;

  const theState = React.useRef();
  React.useEffect(() => {
    theState.current = state;
  }, [state]);

  return (
    <wcContext.Provider
      value={{
        state,
        dispatch,
        // provider,
        // web3Provider,
        // address,
        // chainId,
      }}
    >
      {" "}
      {children}{" "}
    </wcContext.Provider>
  );
});

const useWalletConnectContext = () => useContext(wcContext);

export {
  useWeb3Account,
  Web3AccountProvider,
  useWalletConnectContext,
  WalletConnectContext,
};
